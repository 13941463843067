module.exports = {
  first_name: "First name",
  last_name: "Last name",
  submit: "Submit",
  one_more_step: "One more step",
  thank_you_for_verifying_your_email_address:
    "Thank you for verifying your email address. We just need to know few more things before you can enjoy our exclusive benefits.",
  receive_updates:
    "I would like to receive updates and information on new services and promotions",
};
